<!-- 商品信息 -->
<template>
  <div class="hoho-page">
    <div class="hoho-container">
      <el-alert
        title="请务必正确填写商品数据，否则可能造成无法清关或者货物被扣风险，如随意填写，账号将会被禁止使用"
        type="warning"
        show-icon
      >
      </el-alert>
      <div class="hoho-form-container">
        <el-card>
          <el-form
            label-width="120px"
            :model="modelForm"
            :rules="rules"
            ref="modelForm"
          >
            <div class="common-explain">
              <i class="iconfont icon-icon"></i
              >请务必输入正确的条码，否则可能会影响通关.
            </div>
            <div class="line">
              <div class="line-cell goodscode">
                <el-form-item label="商品条码" prop="goodsCode">
                  <el-input
                    v-model.trim="modelForm.goodsCode"
                    maxlength="15"
                    placeholder="条码仅支持英文和数字组合！"
                    show-word-limit
                    @input="handleCreateQrCode"
                    @clear="handleCreateQrCode"
                    ref="goodsCode"
                    :disabled="modelForm.id ? true : false"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="line-cell nospace">
                <el-form-item
                  ><el-checkbox
                    v-model="modelForm.noCode"
                    @change="handelNoCode"
                    :disabled="modelForm.id ? true : false"
                    >无条码，勾选系统自动产生条码</el-checkbox
                  ></el-form-item
                >
              </div>
              <div class="line-cell qrcode" v-show="modelForm.goodsCode">
                <canvas id="barCode" class="qrcode"></canvas>
                <span class="explain">{{ modelForm.goodsCode }}</span>
              </div>
            </div>
            <template v-if="isNewGoods">
              <div class="common-explain nobott">
                <i class="iconfont icon-icon"></i>提示：商品名称
                必须要准确；禁止使用日语；不能全部都是英文；严禁简单描写：如
                衣服；玩具 这些都是错误的 正确填写 例：POLA美白丸；大正感冒药
                （品牌+内容）
              </div>
              <div class="line">
                <div class="line-cell goodsname">
                  <el-form-item label="商品中文名" prop="goodsName">
                    <el-input
                      v-model.trim="modelForm.goodsName"
                      maxlength="20"
                      placeholder="例如：嘉娜宝眼霜"
                      show-word-limit
                      @blur="translateEn('name')"
                      :disabled="modelForm.id && modelForm.state ? true : false"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="line-translate">
                  <i class="iconfont icon-arrowRight"></i>
                </div>
                <div class="line-cell translate">
                  <el-form-item label="商品英文">
                    <el-input
                      v-model.trim="modelForm.enGoodsName"
                      placeholder="该名称为系统自动翻译，如翻译不正确请直接修改"
                      :disabled="modelForm.id && modelForm.state ? true : false"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="line">
                <div class="line-cell goodsname">
                  <el-form-item label="品牌中文名" prop="brandName">
                    <el-input
                      v-model.trim="modelForm.brandName"
                      maxlength="20"
                      placeholder="例如：嘉娜宝"
                      show-word-limit
                      @blur="translateEn('brand')"
                      :disabled="modelForm.id && modelForm.state ? true : false"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="line-translate">
                  <i class="iconfont icon-arrowRight"></i>
                </div>
                <div class="line-cell translate">
                  <el-form-item label="品牌英文">
                    <el-input
                      v-model.trim="modelForm.enBrandName"
                      placeholder="该名称为系统自动翻译，如翻译不正确请直接修改"
                      :disabled="modelForm.id && modelForm.state ? true : false"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="line auto-height">
                <div class="line-cell goodsname">
                  <el-form-item label="分类中文名" prop="categoryElement">
                    <el-select
                      v-model="categoryElement"
                      @change="handleChageCategory"
                      value-key="guid"
                      filterable
                      placeholder="请选择分类"
                      :disabled="modelForm.id && modelForm.state ? true : false"
                    >
                      <el-option
                        v-for="item in categoryData"
                        :key="item.guid"
                        :label="item.categoryCn"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item
                    label="其他分类"
                    prop="othercategoryName"
                    v-if="categoryElement.guid == 'other'"
                  >
                    <el-input
                      v-model.trim="modelForm.categoryName"
                      maxlength="20"
                      placeholder="例如：眼霜；首饰；保温杯；鞋；服装等"
                      show-word-limit
                      @blur="translateEn('category')"
                      :disabled="modelForm.id && modelForm.state ? true : false"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="line-translate">
                  <i class="iconfont icon-arrowRight"></i>
                </div>
                <div class="line-cell translate">
                  <div>
                    <el-form-item label="品名英文(分类)">
                      <el-input
                        v-model.trim="modelForm.enCategoryName"
                        placeholder="该名称为系统自动翻译，如翻译不正确请直接修改"
                        :disabled="
                          modelForm.id && modelForm.state ? true : false
                        "
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="英文主要成分">
                      <el-input
                        type="textarea"
                        autosize
                        clearable
                        v-model.trim="modelForm.enElement"
                        placeholder="该成分根据您的分类，系统自动推荐主要成分，如不正确请直接修改"
                        :disabled="
                          modelForm.id && modelForm.state ? true : false
                        "
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div class="common-explain">
                <i class="iconfont icon-icon"></i
                >提示：商品含量如果是面膜则需要写明每包的片数
              </div>
              <div class="line">
                <div class="line-cell goodsattr">
                  <el-form-item label="商品含量/规格" prop="goodsAttr">
                    <el-input
                      v-model.trim="modelForm.goodsAttr"
                      maxlength="10"
                      placeholder="例如：15g;720ml;套装；1双；1个等"
                      show-word-limit
                      :disabled="modelForm.id && modelForm.state ? true : false"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="common-explain">
                <i class="iconfont icon-icon"></i>提示：商品价格仅支持数字和整数
              </div>
              <div class="line">
                <div class="line-cell goodsattr">
                  <el-form-item label="价格JPY" prop="goodsPrice">
                    <el-input
                      type="number"
                      v-model.trim="modelForm.goodsPrice"
                      maxlength="7"
                      placeholder="输入商品日元价格"
                      show-word-limit
                      :disabled="modelForm.id && modelForm.state ? true : false"
                      ><template slot="append">円</template></el-input
                    >
                  </el-form-item>
                </div>
              </div>
              <div class="common-explain">
                <i class="iconfont icon-icon"></i>提示：净重输入公斤 1kg = 1000g
              </div>
              <div class="line">
                <div class="line-cell goodsattr">
                  <el-form-item label="净重(公斤)" prop="goodsWeight">
                    <el-input
                      type="number"
                      v-model.trim="modelForm.goodsWeight"
                      placeholder="输入商品净重公斤"
                      show-word-limit
                      :disabled="modelForm.id && modelForm.state ? true : false"
                      ><template slot="append">KG</template></el-input
                    >
                  </el-form-item>
                </div>
              </div>
              <div class="line auto-height">
                <div class="line-cell">
                  <el-form-item label="包装单位" prop="goodsUnit">
                    <el-radio-group
                      v-model="modelForm.goodsUnit"
                      :disabled="modelForm.id && modelForm.state ? true : false"
                    >
                      <el-radio
                        v-for="item in packUnit"
                        :label="item.value"
                        :key="item.key"
                      ></el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
                <div class="line-cell nospace">
                  <el-form-item prop="otherUnit">
                    <el-input
                      v-model.trim="modelForm.otherUnit"
                      maxlength="6"
                      placeholder="输入包装单位"
                      show-word-limit
                      :disabled="modelForm.id && modelForm.state ? true : false"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <el-divider content-position="left">以下为建议填写</el-divider>

              <div class="line auto-height">
                <div class="upload">
                  <div class="title">商品图片</div>
                  <element-upload
                    @setpicture="handleSetPricture"
                    @delpicture="handleDelPricture"
                    :disabled="modelForm.id && modelForm.state ? true : false"
                    :imgUrl="modelForm.goodsImg"
                    :images="[modelForm.goodsImg]"
                  ></element-upload>
                </div>
              </div>
              <div class="line">
                <div class="line-cell engoodsname">
                  <el-form-item label="商品日文名称">
                    <el-input
                      v-model.trim="modelForm.jpGoodsName"
                      maxlength="50"
                      placeholder="输入商品日文名称"
                      show-word-limit
                      :disabled="modelForm.id && modelForm.state ? true : false"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="common-explain">
                <i class="iconfont icon-icon"></i
                >提示：第三方编码映射多个用逗号分隔
              </div>
              <div class="line">
                <div class="line-cell engoodsname">
                  <el-form-item label="第三方编码映射">
                    <el-input
                      type="textarea"
                      autosize
                      placeholder="请输入映射编码"
                      v-model="modelForm.externalCode"
                      :disabled="modelForm.id && modelForm.state ? true : false"
                    >
                    </el-input
                  ></el-form-item>
                </div>
              </div>
            </template>
          </el-form>
        </el-card>
      </div>
    </div>
    <template v-if="isNewGoods && !modelForm.state">
      <div class="hoho-bottom-buts" v-if="!modelForm.state">
        <el-button type="primary" @click="subimtForm">提交商品</el-button>
      </div>
    </template>
  </div>
</template>

<script>
import JsBarcode from 'jsbarcode'
import { vxRule } from '../utils/validator'
import ElementUpload from './element-upload.vue'
import Goods from '../api/goods'
import Category from '../api/category'
import Common from '../api/common'
export default {
  components: { ElementUpload },
  name: '',
  data() {
    // 验证包装规格
    let verifyOtherUnit = (rule, value, callback) => {
      if (this.modelForm.goodsUnit == '其他' && value === '') {
        callback(new Error('请输入包装单位'))
      } else {
        callback()
      }
    }
    let verifyGoodsCode = (rule, value, callback) => {
      try {
        const regex = /^[a-zA-Z0-9]+$/
        if (!regex.test(value)) {
          callback(new Error('商品条码仅支持英文和数字组合！'))
          this.isNewGoods = false
        }
      } catch (error) {}

      if (value) {
        if (!this.modelForm.id) {
          Goods.codeIsExist({ code: value }, false).then((res) => {
            if (res.state && res.data > 0) {
              callback(new Error('商品已存在，可直接使用'))
              this.isNewGoods = false
            } else {
              this.isNewGoods = true
              callback()
            }
          })
        } else {
          this.isNewGoods = true
          callback()
        }
      }
    }
    let verifyOtherCategory = (rule, value, callback) => {
      if (this.categoryElement.guid == 'other') {
        if (!this.modelForm.categoryName) {
          callback(new Error('请输入其他商品分类'))
        } else {
          callback()
        }
      }
    }
    let verifyCategory = (rule, value, callback) => {
      if (this.categoryElement && this.categoryElement.guid) {
        callback()
      } else {
        callback(new Error('请输入其他商品分类'))
      }
    }
    return {
      labelPosition: 'right',
      modelForm: {
        id: '', // 商品ID
        state: 0, // 商品状态 1已审核
        goodsCode: '', // 商品编码
        noCode: false, // 是否选择无商品编码
        goodsName: '', // 中文商品名称
        brandName: '', // 中文品牌
        categoryName: '', // 中文分类
        enGoodsName: '', // 英文商品名称
        enBrandName: '', // 英文品牌
        enCategoryName: '', // 英文分类
        enElement: '', // 英文成分
        goodsAttr: '', // 商品含量/规格
        goodsPrice: '', // 价格
        goodsWeight: '', // 净重
        goodsUnit: '', // 包装单位
        otherUnit: '', // 其他包装单位
        goodsImg: '', // 商品图
        jpGoodsName: '', // 商品日文名
        externalCode: '', // 第三方编码
        briefComposition: '' // 中文简要成份
      },
      packUnit: [
        { key: '1', value: '个' },
        { key: '2', value: '瓶' },
        { key: '3', value: '盒' },
        { key: '4', value: '件' },
        { key: '5', value: '桶' },
        { key: '6', value: '套' },
        { key: '7', value: '双' },
        { key: '8', value: '其他' }
      ],
      rules: {
        // goodsCode: vxRule(
        //   true,
        //   'Encode2',
        //   'change',
        //   '商品条码仅支持英文和数字组合！'
        // ),
        goodsCode: [
          { validator: verifyGoodsCode, required: true, trigger: 'change' }
        ],
        goodsName: vxRule(true, null, 'blur', '请输入商品中文名'),
        brandName: vxRule(true, null, 'blur', '请输入品牌中文名'),
        categoryElement: [
          { validator: verifyCategory, required: true, trigger: 'blur' }
        ],
        goodsAttr: vxRule(true, null, 'blur', '请输入商品含量/规格'),
        goodsPrice: vxRule(true, 'IntPlus', 'blur', '商品价格仅支持数字和整数'),
        goodsWeight: vxRule(true, null, 'blur', '请输入净重(公斤)'),
        goodsUnit: vxRule(true, null, '', '请选择包装单位'),
        otherUnit: [
          { validator: verifyOtherUnit, required: true, trigger: 'blur' }
        ],
        othercategoryName: [
          { validator: verifyOtherCategory, required: true, trigger: 'blur' }
        ]
      },
      isNewGoods: false, // 产品是否存在，如果不存在为真
      categoryData: [], // 商品分类
      categoryElement: '', // 商品分类 成分
      element: '' // 根据中文分类获取的英文主要成分
    }
  },
  computed: {},
  watch: {},
  created() {
    if (this.$route.query.id) {
      this.modelForm.id = this.$route.query.id
      this.getGoodsByCode(this.modelForm.id)
    }
    this.getCategoryList()
  },
  mounted() {},
  destroyed() {},
  methods: {
    handleChageCategory(obj) {
      this.modelForm.briefComposition = ''
      this.element = ''
      if (obj.guid != 'other') {
        this.element = obj.ingredientsEn
        this.modelForm.briefComposition = obj.ingredientsCn
      }
    },
    // 获取分类
    getCategoryList() {
      Category.findAllList({}).then((res) => {
        if (res.state) {
          this.categoryData = res.data
          this.categoryData.unshift({
            categoryCn: '新建分类',
            guid: 'other',
            ingredientsCn: '',
            ingredientsEn: '',
            state: 1
          })
          // this.categoryElement = {
          //   categoryCn: '新建分类',
          //   guid: 'other',
          //   ingredientsCn: '',
          //   ingredientsEn: '',
          //   state: 1
          // }
        }
      })
    },
    // 通过商品编码获取商品
    getGoodsByCode(id) {
      Goods.getById({ id }).then((res) => {
        if (res.state) {
          this.modelForm.id = res.data.id
          this.modelForm.state = res.data.state
          this.modelForm.goodsCode = res.data.code
          this.modelForm.goodsName = res.data.nameCn
          this.modelForm.brandName = res.data.brandCn
          this.modelForm.categoryName = res.data.shortNameCn
          this.modelForm.enGoodsName = res.data.nameEn
          this.modelForm.enBrandName = res.data.brandEn
          this.modelForm.enCategoryName = res.data.category1En
          this.modelForm.enElement = res.data.briefCompositionEn
          this.modelForm.goodsAttr = res.data.attribute
          this.modelForm.goodsPrice = res.data.goodsfeeJp
          this.modelForm.goodsWeight = res.data.suttleWeight
          this.modelForm.briefComposition = res.data.briefComposition
          const _unit = this.packUnit.find((item) => {
            return item.value == res.data.packageUnit || ''
          })
          if (!_unit) {
            this.modelForm.goodsUnit = '其他'
            this.modelForm.otherUnit = res.data.packageUnit || ''
          } else {
            this.modelForm.goodsUnit = res.data.packageUnit || ''
          }
          this.modelForm.goodsImg = res.data.imgs
          this.modelForm.jpGoodsName = res.data.nameJp
          this.modelForm.externalCode = ''
          this.bindBarCode('#barCode', this.modelForm.goodsCode)
        }
      })
    },
    // 删除图片
    handleDelPricture(img) {
      this.modelForm.goodsImg = ''
    },
    // 上传图片赋值
    handleSetPricture(imgpath) {
      console.log(imgpath)
      this.modelForm.goodsImg = imgpath
    },
    // 提交商品
    subimtForm() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          if (this.categoryElement.guid != 'other') {
            this.modelForm.categoryName = this.categoryElement.categoryCn
          } else {
            Category.add(
              { categoryCn: this.modelForm.categoryName },
              false
            ).then((res) => {
              if (res.state) {
                this.getCategoryList()
              }
              console.log('addCategory', res)
            })
          }
          let _unit =
            this.modelForm.goodsUnit != '其他'
              ? this.modelForm.goodsUnit
              : this.modelForm.otherUnit
          if (!this.modelForm.enElement) {
            this.modelForm.enElement = this.element
          }
          Goods.add({
            id: this.modelForm.id,
            code: this.modelForm.goodsCode,
            nameCn: this.modelForm.goodsName,
            brandCn: this.modelForm.brandName,
            shortNameCn: this.modelForm.categoryName,
            nameEn: this.modelForm.enGoodsName,
            brandEn: this.modelForm.enBrandName,
            category1En: this.modelForm.enCategoryName,
            briefCompositionEn: this.modelForm.enElement,
            attribute: this.modelForm.goodsAttr,
            goodsfeeJp: this.modelForm.goodsPrice,
            suttleWeight: this.modelForm.goodsWeight,
            unit: _unit,
            packageUnit: _unit,
            imgs: this.modelForm.goodsImg,
            nameJp: this.modelForm.jpGoodsName,
            briefComposition: this.modelForm.briefComposition
          }).then((res) => {
            console.log(res)
            if (res.state) {
              this.$message.success('提交成功')
              this.$refs.modelForm.resetFields()
              this.modelForm.enGoodsName = ''
              this.modelForm.enBrandName = ''
              this.modelForm.enCategoryName = ''
              this.modelForm.enElement = ''
              this.modelForm.otherUnit = ''
              this.modelForm.goodsImg = ''
              this.modelForm.jpGoodsName = ''
              this.isNewGoods = false
              this.categoryElement = ''
              this.element = ''
              this.modelForm.briefComposition = ''
              // this.$confirm('提交成功',  {
              //   confirmButtonText: '继续新增商品',
              //   cancelButtonText: '返回',
              //   type: 'success',
              //   center: true
              // })
              //   .then(() => {
              //     this.$message({
              //       type: 'success',
              //       message: '继续新增商品!'
              //     })
              //   })
              //   .catch(() => {
              //     this.$message({
              //       type: 'info',
              //       message: '已返回'
              //     })
              //   })
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 生成条码
    bindBarCode(selector, data) {
      if (data) {
        JsBarcode(selector, data, {
          background: '#eee',
          displayValue: false,
          // width: 1,
          height: 80, // 一维码的高度
          margin: 10 // 一维码与容器的margin
        })
      }
    },
    // 输入商品编码生成条码
    handleCreateQrCode() {
      if (this.modelForm.goodsCode) {
        this.bindBarCode('#barCode', this.modelForm.goodsCode)
      } else {
        this.modelForm.noCode = false
      }
    },
    // 生成商品code
    handleCreateGoodsCode() {
      Goods.createGoodsCode({}).then((res) => {
        if (res.state) {
          this.modelForm.goodsCode = res.data
          this.bindBarCode('#barCode', res.data)
        }
      })
    },
    // 无条码change事件
    handelNoCode() {
      if (this.modelForm.noCode) {
        this.handleCreateGoodsCode()
      } else {
        this.modelForm.goodsCode = ''
      }
    },
    // 翻译
    translateEn(type) {
      const self = this
      let _content = ''
      switch (type) {
        case 'name':
          _content = this.modelForm.goodsName
          break
        case 'brand':
          _content = this.modelForm.brandName
          break
        case 'category':
          _content = this.modelForm.categoryName
          if (_content) {
            Goods.categoryIntoEnComposition({ category: _content }, false).then(
              (res) => {
                if (res.state) {
                  self.modelForm.enElement = res.data
                }
              }
            )
          }
          break
      }
      if (_content) {
        Common.translate({ content: _content, language: 'en' }, false).then(
          (res) => {
            if (res.state) {
              switch (type) {
                case 'name':
                  self.modelForm.enGoodsName = res.data
                  break
                case 'brand':
                  self.modelForm.enBrandName = res.data
                  break
                case 'category':
                  self.modelForm.enCategoryName = res.data
                  break
              }
            }
          }
        )
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hoho-page {
  height: 100vh;
  overflow: hidden;
}
.hoho-container {
  overflow-y: auto;
  padding: 10px;
  flex: 1;
}
.common-explain {
  display: flex;
  align-items: center;
  color: rgb(229, 161, 71);
  margin-bottom: 5px;
  font-size: 12px;
  .iconfont {
    margin-right: 2px;
    font-size: 14px;
  }
}
.common-explain.nobott {
  margin-bottom: 0;
}
.line {
  display: flex;
  align-content: center;
  height: 58px;
  .qrcode {
    height: 36px;
  }
  .line-cell.goodscode {
    width: 340px;
  }
  .line-cell.qrcode {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    .explain {
      font-size: 10px;
      transform: scale(0.8);
      margin-top: -2px;
    }
  }
  .line-cell.nospace {
    ::v-deep {
      .el-form-item__content {
        margin-left: 10px !important;
      }
    }
  }
  .line-cell.goodsname {
    width: 470px;
    padding-top: 5px;
    ::v-deep {
      .el-select {
        width: 100% !important;
      }
    }
  }
  .line-translate {
    height: 41px;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .line-cell.translate {
    width: 490px;
    background: rgb(253, 246, 237);
    padding-right: 20px;
    padding-top: 5px;
    margin-left: 10px;
  }
  .line-cell.goodsattr {
    width: 400px;
  }
  .upload {
    display: flex;
    margin: 22px 0;
    .title {
      width: 110px;
      text-align: right;
      margin-right: 10px;
      color: rgb(96, 98, 102);
    }
  }
  .line-cell.engoodsname {
    width: 480px;
  }
}
.line.auto-height {
  height: auto;
}
</style>
