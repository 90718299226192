import { post, get } from '../plugins/request'
import config from '../config/index';

export default {
  // 列表查询
  findAllList(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/category-and-ingredients/findAllList`, params)
  },
  // 修改分类
  edit(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/category-and-ingredients/edit`, params)
  },
  // 获取详情
  detailByGuid(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/category-and-ingredients/detailByGuid`, params)
  },
  // 添加
  add(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/category-and-ingredients/add`, params)
  },
  // 列表查询
  page(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/category-and-ingredients/page`, params)
  },
}
