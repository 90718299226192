import { post, get } from '../plugins/request'
import config from '../config/index';

export default {
  // 生成商品code
  createGoodsCode(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/logistics-goods/createGoodsCode`, params)
  },
  // 新增商品
  add(params, loading = true) {
    Object.assign(params, { loading })
    if (params.id) {
      return post(`${config.APIURL}/logistics-goods/edit`, params)
    } else {
      return post(`${config.APIURL}/logistics-goods/add`, params)
    }
  },
  // 编辑商品
  edit(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/logistics-goods/edit`, params)
  },
  // 根据code查询商品
  getByCode(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/logistics-goods/getByCode`, params)
  },
  // 根据id查询商品
  getById(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/logistics-goods/getById`, params)
  },
  // 分类转成份
  categoryIntoEnComposition(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/logistics-goods/categoryIntoEnComposition`, params)
  },
  // 商品编码是否可用
  codeIsExist(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/logistics-goods/codeIsExist`, params)
  }
}
