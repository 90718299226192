<template>
  <div>
    <element-goods></element-goods>
  </div>
</template>

<script>
import ElementGoods from '../../components/element-goods.vue'
export default {
  components: {ElementGoods},
  name: '',
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  destroyed() {},
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media (max-width: 599px) {
}
@media (min-width: 599px) {
}
@media (min-width: 960px) {
}
@media (min-width: 990px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1440px) {
}
</style>
