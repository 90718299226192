<!-- 上传附件 -->
<template>
  <div class="upload-page">
    <el-upload
      v-if="!disabled"
      class="upload-demo"
      drag
      action=""
      :multiple="multiple"
      :http-request="handleUpload"
      :show-file-list="false"
      :accept="accept"
      :on-change="beforeAvatarUpload"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip" slot="tip">
        {{ explain }}
      </div>
    </el-upload>
    <div v-if="imgUrl" class="preview-images">
      <el-image
        :src="imgUrl"
        style="width: 180px; height: 180px"
        :preview-src-list="images"
        fit="contain"
      >
      </el-image>
      <div class="handle-img" v-if="!disabled">
        <i class="el-icon-error" @click.stop="handleDelImg"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Common from '../api/common'
export default {
  components: {},
  name: '',
  props: {
    multiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    explain: { type: String, default: '只能上传jpeg,jpg,png文件，且不超过2M' },
    accept: { type: String, default: 'image/*' },
    imgUrl: {
      type: String,
      default: ''
    },
    images: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  destroyed() {},
  methods: {
    beforeAvatarUpload(file) {
      // console.log('before avatar upload', file)
      const is1M = file.size / 1024 / 1024 < 5 // 限制小于5M
      const jpg = file.raw.type === 'image/jpg'
      const png = file.raw.type === 'image/png'
      const jpeg = file.raw.type === 'image/jpeg'

      if (!jpg && !png && !jpeg) {
        this.$message.error('上传文件只能是 JPG 、PNG、JPG格式!')
      }
      if (!is1M) {
        this.$message.error('图片大小不可超过5MB')
      }
      return is1M && (jpg || png || jpeg)
    },
    // 删除图片
    handleDelImg() {
      this.$emit('delpicture', 'del')
    },
    // 上传图片
    handleUpload(res) {
      // console.log('handle upload:', res)
      var file = res.file
      Common.uploadImage({ file }).then((res) => {
        if (res.data.state) {
          this.$emit('setpicture', res.data.data)
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.upload-page {
  display: flex;
  .preview-images {
    width: 180px;
    height: 180px;
    margin-left: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f9f9f9;
    ::v-deep {
      img {
        object-fit: contain !important;
      }
    }
    .handle-img {
      position: absolute;
      z-index: 1;
      color: red;
      top: -8px;
      right: -4px;
      .el-icon-error {
        font-size: 18px;
      }
    }
  }
}
</style>
